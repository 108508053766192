const $ = require('lc-jquery');
const heapTracking = require('./heap-tracking');
const dynamicModuleRegistry = require('dynamic-module-registry');
const postRobot = require('post-robot');

const loginFormHandler = require('./formHandlers/loginFormHandler');
const codeFormHandler = require('./formHandlers/codeFormHandler');
const channelFormHandler = require('./formHandlers/channelFormHandler');

const target = dynamicModuleRegistry.get('widgetTargetURL');
const targetURL = (!target || !target.target) ? '' : target.target;
const forgotPasswordNewWindow = dynamicModuleRegistry.get('forgotPasswordNewWindow');
const enableShowPasswordButton = dynamicModuleRegistry.get('enableShowPasswordButton');

const MISSING_CSRF_TOKEN_ERROR = 'The page expired. Please refresh and try again.';
const UNKNOWN_ERROR = 'An unknown error occurred';
const topWindow = window.parent;

function LoginCore(container, opts) {
  this.opts = opts;
  this.setupWindowConnection();
  this.setContainers(container);
  this.initListeners();
  this.setUpLoginForm();
  // track widget login load
  heapTracking.addWidgetLoginTracking(targetURL);
}

LoginCore.prototype.hideAllNotifications = function() {
  this.$loginForm.find('.diamond-style-notification-box').addClass('hidden');
  this.$codeForm.find('.diamond-style-notification-box').addClass('hidden');
  this.$channelForm.find('.diamond-style-notification-box').addClass('hidden');
};

LoginCore.prototype.resetForm = function() {
  this.$codeForm.find('[name=code]').val('');
  this.$codeForm.find('.enter-code-input').removeClass('is-invalid');
  this.$codeForm.find('.enter-code-input').removeClass('is-valid');
  this.$codeForm.find('[data-notification-box]').removeClass('is-notice');
  this.$codeForm.find('[data-notification-box]').removeClass('is-error');
  this.$codeForm.find('[data-notification-box]').removeClass('is-success');
  this.hideAllNotifications();
};

/*
    When the CSRF token is missing, ui-boreas returns a promise with 403 error automatically, it cannot be mapped on
    login-core with a nice message like all the other statuses, that's why we have to preprocess this status
    in order to avoid a loop of promises.
*/
LoginCore.prototype.formatData = function(data) {
  let formatedData = data;
  // data must be an object and not a promise in order avoid a loop of exceptions
  if (typeof data.then === 'function') {
    formatedData = { status: data.status, message: data.message || UNKNOWN_ERROR };
    if (data.status === 403) {
      formatedData = { status: data.status, message: MISSING_CSRF_TOKEN_ERROR };
    }
  }
  return formatedData;
};

// create a listener for messages from parent
LoginCore.prototype.setupWindowConnection = function setupWindowConnection() {
  if (!this.opts.isWidget) {
    return;
  }
  postRobot.send(topWindow, 'initHandshake', {}).then(function(event) {
    // parent/client handshake confirmed

    const loginEmail = event.data.email || '';
    if (loginEmail) {
      $('input[name=email]').val(loginEmail);
    }
  });
};

LoginCore.prototype.setContainers = function(container) {
  const isJquery = container && (container instanceof $ || container.constructor.prototype.jquery);
  this.$container = isJquery ? container : $(container);
  this.$loginForm = this.$container.find('[data-login-form]');
  this.$codeForm = this.$container.find('[data-code-form]');
  this.$channelForm = this.$container.find('[data-channel-form]');
};

LoginCore.prototype.initListeners = function initListeners() {
  // set onClick listener for all reset password links on form
  this.$loginForm.on('click', '[data-forgot-password-link]', this.forgotPasswordClick.bind(this));
  if (!this.opts.isWidget) {
    return;
  }
  // only if there is a backButton
  this.$loginForm.on('click', '[data-button-back]', this.backButtonClick.bind(this));
  this.$loginForm.on('submit', function() {
    // track widget login attempt
    heapTracking.addWidgetLoginAttempt(targetURL);
  });
  if (enableShowPasswordButton) {
    this.$loginForm.on('click', '[data-show-password-button]', (e) => {
      e.preventDefault();
      const input = this.$container.find('[data-login-form] .password-container [name="password"]');
      const button = this.$container.find('[data-login-form] .password-container [data-show-password-button]');
      if (input.length > 0 && button.length > 0) {
        this.toggleShowInput(input, button);
      }
    });
  }
};

LoginCore.prototype.forgotPasswordClick = function forgotPasswordClick(e) {
  // only widget is listening for this, no need to check again here for isWidget
  e.preventDefault();
  if (!this.opts.isWidget) {
    window.location.href = '/auth/resetPassword/entry';
    return;
  }
  const theme = e.target.attributes['data-theme'] ? e.target.attributes['data-theme'].value : 'lc-blue';
  postRobot.send(topWindow, 'forgotPasswordClick',
    { theme, forgotPasswordNewWindow }).then(function(event) {});
};

LoginCore.prototype.backButtonClick = function backButtonClick(e) {
  e.preventDefault();
  postRobot.send(topWindow, 'backButtonClick', {}).then(function(event) {});
};

LoginCore.prototype.handleResendCodeLink = function handleResendCodeLink(event) {
  event.preventDefault();
  this.$codeForm.find('[data-spinner]').removeClass('hidden');
  this.resetForm();
  $.ajax({
    type: 'POST',
    dataType: 'json',
    data: {
      factorId: this.$codeForm.find('[name=factorId]').val(),
      phoneId: this.$codeForm.find('[name=phoneId]').val(),
      _realm: this.$container.find('[name=_realm]').val()
    },
    processData: true,
    url: $(event.currentTarget).attr('href'),
    error: this.resendCodeHandler.bind(this),
    success: this.resendCodeHandler.bind(this)
  });
};

LoginCore.prototype.resendCodeHandler = function resendCodeHandler(response) {
  const data = this.formatData(response);
  const state = data.status === 200 ? 'notice' : 'error';
  if (this.opts.isWidget) {
    if (data.status !== 200) {
      postRobot.send(topWindow, 'resendCodeError', { data }).then(function(event) {
        return;
      });
    } else {
      postRobot.send(topWindow, 'resendCodeSuccess', { data }).then(function(event) {
        return;
      });
    }
  }

  this.$codeForm.find('[data-spinner]').addClass('hidden');
  this.codeFormManager.setFormState(state, data.message);
};

LoginCore.prototype.handleChangeFactorCodeLink = function handleChangeFactorCodeLink(event) {
  event.preventDefault();
  this.$codeForm.find('[data-spinner]').removeClass('hidden');
  this.resetForm();
  if (!this.$factorsData) {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      data: {
        factorId: this.$container.find('[name=factorId]').val(),
        _realm: this.$container.find('[name=_realm]').val(),
        parentUrl: window.parent.location.href
      },
      processData: true,
      url: $(event.currentTarget).attr('href'),
      error: this.changeFactorCodeHandler.bind(this),
      success: this.changeFactorCodeHandler.bind(this)
    });
  } else {
    this.changeFactorCodeHandler({...this.$factorsData, status: 200});
  }
};

LoginCore.prototype.changeFactorCodeHandler = function changeFactorCodeHandler(response) {
  const data = this.formatData(response);
  const state = data.status === 200 ? 'notice' : 'error';
  if (this.opts.isWidget) {
    if (data.status !== 200) {
      postRobot.send(topWindow, 'changeFactorCodeError', { data }).then(function(event) {
        return;
      });
    } else {
      postRobot.send(topWindow, 'changeFactorCodeSuccess', { data }).then(function(event) {
        return;
      });
    }
  }

  if (state === 'error') {
    // Hide Spinner from code form
    this.$codeForm.find('[data-spinner]').addClass('hidden');
    this.codeFormManager.setFormState(state, data.message);
    return;
  }
  this.showChannelSection(data.factors);
};

LoginCore.prototype.reloadPage = function reloadPage() {
  document.location.reload(true);
};

LoginCore.prototype.reloadParentPage = function reloadParentPage() {
  topWindow.location.reload(true);
};


LoginCore.prototype.showErrorMessage = function showErrorMessage(data, form) {
    if (data.type === 'ERROR') {
      form.find('.diamond-style-notification-box').find('.notification-box').addClass('is-error');
    }
    form.find('.diamond-style-notification-box').find('.notification-box__title').html(data.title);
    form.find('.diamond-style-notification-box').find('.notification-box__message').html(data.message);
    form.find('.diamond-style-notification-box').removeClass('hidden');
};


/*

This flow handles 3 screens that are a continuous flow that according infosec shouln't be accessed directly
Login, Factor Selection and MFA

*/

// Login Form
LoginCore.prototype.setUpLoginForm = function loginSuccessHandler() {
  loginFormHandler.setUpLoginForm.bind(this)();
};

LoginCore.prototype.loginSuccessHandler = function loginSuccessHandler(response) {
  loginFormHandler.loginSuccessHandler.bind(this)(response);
};

LoginCore.prototype.loginErrorHandler = function loginErrorHandler(response) {
  loginFormHandler.loginErrorHandler.bind(this)(response);
};

// Channel Form
LoginCore.prototype.setUpChannelForm = function setUpChannelForm(data) {
  channelFormHandler.setUpChannelForm.bind(this)(data);

};

LoginCore.prototype.showChannelSection = function showChannelSection(data) {
  channelFormHandler.showChannelSection.bind(this)(data);
};


LoginCore.prototype.channelHandler = function channelHandler(response) {
  channelFormHandler.channelHandler.bind(this)(response);
};


// Email MFA Form
LoginCore.prototype.showCodeSection = function showCodeSection(data) {
  codeFormHandler.showCodeSection.bind(this)(data);
};

LoginCore.prototype.setUpCodeForm = function setUpCodeForm() {
  codeFormHandler.setUpCodeForm.bind(this)();
};

LoginCore.prototype.codeHandler = function codeHandler(response) {
  codeFormHandler.codeHandler.bind(this)(response);
};

LoginCore.prototype.toggleShowInput = function toggleShowInput(input, button) {
  if (input.attr('type') === 'text') {
    button.html('SHOW');
    input.attr('type', 'password');
  } else {
    button.html('HIDE');
    input.attr('type', 'text');
  }
};


module.exports = LoginCore;

