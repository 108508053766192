module.exports = {
    'FACTOR_LINK_TEXT': 'Choose a Different Method',
    'NOT_WORKING_TEXT': 'Not working?',
    'MAIN_TITLE': 'Please verify your identity to complete your log in',
    'OTP_EMAIL': {
        title: 'We sent a code to your email'
    },
    'OTP_SMS': {
        title: 'We\'ve texted you with a code'
    },
    'OTP_VOICE': {
        title: 'We\'ll call you in a moment'
    }
};

