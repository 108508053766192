const LoginCore = require('../../shared_modules/login-core-client');
require('../../shared_modules/diamond-style-look');
const dynamicModuleRegistery = require('dynamic-module-registry');

const QRCode = require('qrcode');
const canvas = document.getElementById('canvas');
const origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
let redirectURL = `${origin}/auth/redirect`;
function LoginPage(container) {
  this.setRealm();
  this.initCore(container);
  if (this.realm === 'lca') {
    require('../../shared_modules/base-layout');
  } else {
    require('../../shared_modules/base-layout-propulsion');
  }
}

LoginPage.prototype.setRealm = function setRealm(isWidget) {
  const clientContext = dynamicModuleRegistery.get('client-context') || {};
  this.realm = clientContext.realm;
};

LoginPage.prototype.initCore = function initCore(container) {
  const opts = {
    isWidget: false,
    loginUrl: this.realm === 'lca' ? '/auth/lca/login' : '/auth/login',
    loginSubmitUrl: this.realm === 'lca' ? '/auth/lca/login/submit' : '/auth/login/submit',
    codeSubmitUrl: this.realm === 'lca' ? '/auth/lca/login/submit/code' : '/auth/login/submit/code',
    channelSubmitUrl: '/auth/login/submit/factor'
  };
  this.core = new LoginCore(container, opts);
};

QRCode.toCanvas(canvas, redirectURL, {
  color: {
    dark: '#000000',
    light: '#ffffff'
  },
  margin: 1
}, function(error) {
  if (error) console.error(error);
});

new LoginPage('[data-login-page]');
module.exports = LoginPage;

require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/pages/login/login.scss');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/pages/login/images/logged-out-icon.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/pages/login/images/login_banner.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/pages/login/images/open_in_new.svg');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/pages/login/images/privacy-security-icon.png');
require('/var/lib/jenkins/workspace/auth-ui_Release_0/src/pages/login/images/sample_qr.svg');