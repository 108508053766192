const FormManager = require('../../xd-form-manager');
const heapTracking = require('../heap-tracking');
const postRobot = require('post-robot');
const objectPath = require('object-path');
const dynamicModuleRegistry = require('dynamic-module-registry');
const target = dynamicModuleRegistry.get('widgetTargetURL');
const targetURL = (!target || !target.target) ? '' : target.target;
const codeFormText = require('./codeFormText');
const { getLastDigits } = require('./channelFormHandler');
const topWindow = window.parent;

function setUpCodeForm() {
    var options = {
        schemas: {
            validator: require('../code-schema')
        },
        endPoint: {
            url: this.opts.codeSubmitUrl,
            dataType: 'json',
            method: 'POST',
            handlers: {
                success: this.codeHandler.bind(this),
                error: this.codeHandler.bind(this)
            }
        },
        triggers: {
            validator: 'field'
        },
        errorDisplayStyle: 'field',
        spinner: true
    };

    if (!this.codeFormManager) {
        this.codeFormManager = new FormManager(this.$container.find('[data-code-view]'), options);
        this.codeFormManager.on('success', this.codeHandler.bind(this));
        this.codeFormManager.on('validationSuccess', () => this.hideAllNotifications());
        this.codeFormManager.on('validationError', () => this.hideAllNotifications());
        // enable button now that loginFormManager is ready
        this.$codeForm.find('[type=submit]').prop('disabled', false);
        var self = this;
        this.$codeForm.on('click', '[data-reload-link]', function(event) {
            // entered code wrong 3+ times. have to start over with email/pass login
            // this way auth-service can tell if account is locked, login form can show errs, etc
            event.preventDefault();
            return self.reloadParentPage();
        });
    }
}

function showCodeSection(data) {
    this.numCodeAttempts = 0;
    const factors = objectPath.get(data, 'factors', []);
    const selectedFactor = objectPath.get(data, 'selectedFactor', null) || objectPath.get(data, 'factors.0', {});
    const selectedFactorType = objectPath.get(selectedFactor, 'type', '');

    if (selectedFactorType === 'OTP_APP') {
        heapTracking.addAPPMFASectionTracking(targetURL);
    } else if (selectedFactorType === 'OTP_EMAIL') {
        this.$container.find('[data-masked-email]').text(objectPath.get(selectedFactor, 'profile.email', ''));
        // track widget email section load
        heapTracking.addEmailMFASectionTracking(targetURL);
    } else {
        this.$container.find('[data-masked-mobile]').text(objectPath.get(selectedFactor, 'profile.phone_number', ''));
        // track widget SMS or PHONE section load
        if (selectedFactorType === 'OTP_SMS') {
            heapTracking.addSMSMFASectionTracking(targetURL);
        } else {
            heapTracking.addVoiceMFASectionTracking(targetURL);
        }
    }

    // update visible text
    this.$container.find('[data-title]').text(codeFormText.MAIN_TITLE);
    if (factors.length > 1) {
        this.$container.find('[data-code-factor-text]').text(codeFormText.NOT_WORKING_TEXT);
        this.$container.find('[data-code-factor-link]').text(codeFormText.FACTOR_LINK_TEXT);
    }
    // update hidden form fields
    this.$codeForm.find('[name=factorId]').val(selectedFactor.id);
    this.$codeForm.find('[name=phoneId]').val(selectedFactor.phone_id);
    this.$codeForm.find('[name=factorType]').val(selectedFactorType);

    // setup form
    this.setUpCodeForm();

    // Show/Hide the Resend Code link based on selectedFactorType
    const resendCodeLink = this.$container.find('[data-resend-button]');
    const rememberDeviceLink = this.$container.find('[remember-device-opt]');
    if (selectedFactorType === 'OTP_APP') {
        resendCodeLink.addClass('hidden');
        rememberDeviceLink.addClass('hidden');
    } else {
        resendCodeLink.removeClass('hidden');
        rememberDeviceLink.removeClass('hidden');
    }

    this.$container.find('[data-resend-code-link]').off();
    this.$container.find('[data-code-factor-link]').off();

    this.$container.find('[data-resend-code-link]').on('click', this.handleResendCodeLink.bind(this));
    this.$container.find('[data-code-factor-link]').on('click', this.handleChangeFactorCodeLink.bind(this));

    // switch views
    this.$container.find('[data-login-view]').addClass('hidden');
    this.$container.find('[data-channel-view]').addClass('hidden');
    this.$container.find('[data-code-view]').removeClass('hidden');
    this.$container.find('[data-code-view] [data-selected-factor]').html(getSelectedFactor(selectedFactor));
    this.$codeForm.find('[data-spinner]').addClass('hidden');

    // track widget code section load
    heapTracking.addCodeSectionTracking(targetURL);
    if (this.opts.isWidget) {
        postRobot.send(topWindow, 'showEnterCodeScreen', { factors: this.factors }).then(function(event) {
            return;
        });
    }
}

function codeHandler(response) {
    this.$codeForm.find('.diamond-style-notification-box').addClass('hidden');
    const data = this.formatData(response);
    if (data.status === 200) {
        heapTracking.addSuccessLogin(targetURL);
        if (this.opts.isWidget) {
            postRobot.send(topWindow, 'loginSuccess', { data }).then(function(event) {
                return;
            });
        } else {
            // reload to trigger the redirect logic when logged in
            return this.reloadPage();
        }
        return;
    }

    // TODO: remove this counter. auth-service should indicate this to us with
    // a different statusCode or errName
    this.numCodeAttempts += 1;
    if (data.status === 404 || this.numCodeAttempts >= 3) {
        // change resend link to be login link because can't resend anymore since
        // session has been invalidated
        this.$codeForm.find('[data-resend-code-link]')
            .attr('href', this.opts.loginUrl)
            .text('Re-enter your email and password')
            .removeAttr('data-resend-code-link')
            .attr('data-reload-link', '');

        // todo, don't override message when above TODO about counter/auth-service is resolved
        // when token is present on the parent's url, it should ask only for password.
        var linkMessage = window.top.location.href.includes('token=') ? 're-enter your password' : 're-enter your email and password';
        data.message = `The information you entered doesn\'t match our records. Please <a data-reload-link href="#">${linkMessage}</a>.`;
    }

    if (this.opts.isWidget) {
        this.codeFormManager.setFormState('error', data.message);
        postRobot.send(topWindow, 'loginError', { data }).then(function(event) {
            return;
        });
    } else {
        const messageData = {
            'title': 'Error Logging In',
            'message': data.message,
            'type': 'ERROR'
        };
        this.codeFormManager.setFormState('error');
        this.showErrorMessage(messageData, this.$codeForm);
    }
    this.codeFormManager.enableForm();
}

function getSelectedFactor(data) {
    const messages = {
        OTP_SMS: (info) => `We sent a code to your phone number ending in <span>${info}</span>.`,
        OTP_VOICE: (info) => `We’ll call your phone number ending in <span>${info}</span> to give you a code.`,
        OTP_EMAIL: (info) => `We sent a code to <span>${info}</span>.`,
        OTP_APP: (info) => `Enter the code on <span>${info}</span>.`
    };

    const getContactInfo = (type, profile) => {
        const defaultMessage = 'You will get a 6-digit verification code.';
        if (type === 'OTP_SMS' || type === 'OTP_VOICE') {
            return getLastDigits(profile.phone_number) || defaultMessage;
        }
        return profile[type === 'OTP_EMAIL' ? 'email' : 'appInfo'] || defaultMessage;
    };

    const getInfoById = (profile, id, type) => {
        const key = type === 'phone' ? 'all_phones' : 'devices';
        const matchedItem = profile?.[key]?.find(item => type === 'phone' ? item.id === id : item.alchemyTotpDeviceId === Number(id));
        return matchedItem?.[type === 'phone' ? 'phoneNumber' : 'name'] || null;
    };

    const { profile, phone_id, type } = data;
    const phoneNumber = getInfoById(profile, phone_id, 'phone');
    const appName = getInfoById(profile, phone_id, 'device');
    const contactInfo = getContactInfo(type, { ...profile, phone_number: phoneNumber, appInfo: appName });

    return messages[type]?.(contactInfo) || 'Invalid type provided.';
}

module.exports = {
    setUpCodeForm,
    showCodeSection,
    codeHandler,
    getSelectedFactor
};

