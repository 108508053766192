const objectPath = require('object-path');

const addInteraction = (type, target) => {
  try {
    const parent = objectPath.get(window, 'parent.location.pathname', '');
    if (!parent && !target) {
      return;
    }
    const eventInfo = {
      eventName: type,
      PARENT_URL: parent,
      TARGET_URL: target
    };
    if (window.lcTracking && window.lcTracking.trackCustomEvent) {
      window.lcTracking.trackCustomEvent(eventInfo);
    } else {
      console.error('Could not find heap object to track custom event.', eventInfo);
    }
  } catch (err) {
    console.error('Could not track heap custom event.', err);
  }
};

const addWidgetLoginTracking = (target) => {
  addInteraction('AuthWidgetLoginTracking', target);
};

const addWidgetLoginAttempt = (target) => {
  addInteraction('AuthWidgetLoginAttempt', target);
};

const addCodeSectionTracking = (target) => {
  addInteraction('AuthWidgetCodeSectionTracking', target);
};

const addMFASelectionSectionTracking = (target) => {
  addInteraction('AuthWidgetSelectionSectionTracking', target);
};

const addAPPMFASectionTracking = (target) => {
  addInteraction('AuthWidgetAPPSectionTracking', target);
};

const addSMSMFASectionTracking = (target) => {
  addInteraction('AuthWidgetSMSSectionTracking', target);
};

const addVoiceMFASectionTracking = (target) => {
  addInteraction('AuthWidgetVoiceSectionTracking', target);
};

const addEmailMFASectionTracking = (target) => {
  addInteraction('AuthWidgetEmailSectionTracking', target);
};

const addSuccessLogin = (target) => {
  addInteraction('AuthLoginSuccess', target);
};

const addLockedAccount = (target) => {
  addInteraction('servicing_lcauth_login_fail', target);
};


const heapTracking = {
  addInteraction,
  addWidgetLoginAttempt,
  addWidgetLoginTracking,
  addCodeSectionTracking,
  addAPPMFASectionTracking,
  addMFASelectionSectionTracking,
  addSMSMFASectionTracking,
  addVoiceMFASectionTracking,
  addEmailMFASectionTracking,
  addSuccessLogin,
  addLockedAccount
};

module.exports = heapTracking;

