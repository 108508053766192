var $ = require('lc-jquery');

/**
 * Represents a Notification Box.
 * @constructor
 * @classdesc Shows form-level (or even page-level) messages to the user positioned statically at the top of window. Can be style to be generic notices, error messages, or success messages.
 * @param {Object|String} container - A jQuery object, direct DOM reference, or selector string
 * @param {Object} [options] - A set of options used to configure the notification. Options listed alphabetically below.
 * @param {Integer} [options.timedClose] - If given, notifications shown will auto collapse after this duration in ms.
 * @param {Boolean} [options.closeButton] - Default `true`. True means show an 'x' button the user can click to close; false means do not display.
 */
function NotificationBox(container, options) {
  this.setContainers(container);
  this.setOptions(options);
  this.initCloseButton();
  this.addListeners();

  this.states = {
    success: 'is-success',
    error: 'is-error',
    notice: 'is-notice'
  };
  this.currentState = '';
}

/**
 * Set the container and descendant jQuery elements used by the Notification Box.
 * @function setContainers
 * @memberof NotificationBox
 * @instance
 * @param {Object|String} container - A jQuery object, direct DOM reference, or selector string
 */
NotificationBox.prototype.setContainers = function setContainers(container) {
  var isJquery = container && (container instanceof $ || container.constructor.prototype.jquery);
  var $container = isJquery ? container : $(container);
  this.$container = $container.find('[data-notification-box]');
  this.$iconContainer = $container.find('[data-notification-icon]');
  this.$messageContainer = $container.find('[data-notification-message]');
};


/**
 * Assign the options passed in and set default for the closeButton
 * @function setContainers
 * @memberof NotificationBox
 * @instance
 * @param {Object} options - Configuration object passed into the constructor
 */
NotificationBox.prototype.setOptions = function setOptions(options) {
  this.options = options || {};

  if (this.options.closeButton !== false) {
    this.options.closeButton = true;
  }
};


/**
 * Listens for events triggered on the $container to display messages via other classes like xd-form-manager
 * @function addListeners
 * @memberof NotificationBox
 * @instance
 */
NotificationBox.prototype.addListeners = function addListeners() {
  var self = this;

  this.$container.on('error', function(event, message) {
    event.preventDefault();
    self.showErrors(message, 10000); // Show message for 10 seconds
  });

  this.$container.on('success', function(event, message) {
    event.preventDefault();
    self.showSuccess(message, 10000); // Show message for 10 seconds
  });

  this.$container.on('notice', function(event, message) {
    event.preventDefault();
    self.showNotice(message, 10000); // Show message for 10 seconds
  });
};


/**
 * Returns the three visual states (error, success, notice) as a css selector
 * @function isStateValid
 * @memberof NotificationBox
 * @instance
 * @returns {state} - potentially one of the three states in this.states
 */
NotificationBox.prototype.isStateValid = function isStateValid(state) {
  return this.states.hasOwnProperty(state);
};


/**
 * If the configuration allows the close button, display it and set a listener on it to close when clicked
 * @function initCloseButton
 * @memberof NotificationBox
 * @instance
 */
NotificationBox.prototype.initCloseButton = function initCloseButton() {
  if (this.options.closeButton) {
    this.$container
      .find('[data-notification-icon]')
      .removeClass('hidden')
      .on('click', $.proxy(this.collapse, this));
  }
};


/**
* Hides the notificaiton box with css animation and remove any previous HTML contents
* @function setContainers
* @memberof NotificationBox
* @instance
*/
NotificationBox.prototype.collapse = function collapse() {
  if (this.currentState && this.isStateValid(this.currentState)) {
    this.$container.removeClass(this.states[this.currentState]);
    this.$iconContainer.removeClass(this.states[this.currentState]);
    this.$messageContainer.removeClass(this.states[this.currentState]);
  }
  this.$messageContainer.empty();
  this.currentState = '';
};


/**
* Reveals the notifcation box with CSS animation with a given visual state
* @function alertClass
* @memberof NotificationBox
* @instance
*/
NotificationBox.prototype.expand = function expand() {
  if (this.currentState && this.isStateValid(this.currentState)) {
    this.$container.addClass(this.states[this.currentState]);
    this.$iconContainer.addClass(this.states[this.currentState]);
    this.$messageContainer.addClass(this.states[this.currentState]);
  }
};


/**
* normalize errors to be an array of objects with `message` property
*   assumes errors come in one of three ways:
*     1. singular string
*     2. array of objects, each with `message` and `error` property
*     3. singular object with `message` and `error` property
*        e.g., {error: 'ERROR_KEY', message: 'text of error message'}
* @function normalizeErrors
* @memberof NotificationBox
* @instance
* @param {String|Array} errors - an array or string of error objects containing a message key
* @returns {Array} - normalized array of errors with `message` and `error` property
*/
NotificationBox.prototype.normalizeErrors = function normalizeErrors(errors) {
  var normalized = [];
  if (typeof errors === 'string') {
    normalized.push({
      message: errors,
      error: ''
    });
  } else if (errors instanceof Array) {
    // this will accept an arrays of objects with `message`
    // properties as well as arrays of strings
    errors.forEach(function(error) {
      normalized.push({
        message: error.message || error,
        error: error.error || ''
      });
    });
  } else {
    // errors is a singular object
    normalized.push({
      message: errors.message,
      error: errors.error || ''
    });
  }
  return normalized;
};


/**
* Initate a timed close
* @function initTimedClose
* @memberof NotificationBox
* @instance
* @param {Integer} [timedClose] - duration for the notification box to be displayed before closing. If left blank and a value was passed into the constructor for `options.timedClose` that value will be used instead. If both are undefined, the box will not automatically close itself.
*/
NotificationBox.prototype.initTimedClose = function initTimedClose(timedClose) {
  var duration = timedClose || this.options.timedClose;
  if (duration) {
    var self = this;
    setTimeout(function() {
      self.collapse();
    }, duration);
  }
};


/**
* Show one or more error messages to the user. Errors will be normalized first via `this.normalizeErrors()`
* @function showErrors
* @memberof NotificationBox
* @instance
* @param {String|Array} errors - a message as a string or an array of error objects containing at minimum a message key
* @param {Integer} [timedClose] - the time (in ms) after which to close this one message
*/
NotificationBox.prototype.showErrors = function showErrors(errors, timedClose) {
  errors = this.normalizeErrors(errors);
  var messages = '';

  errors.forEach(function(error) {
    messages += (messages ? '<br>' : '') + error.message;
  });

  this.collapse();
  this.$messageContainer.html(messages);
  this.currentState = 'error';
  this.expand();
  this.initTimedClose(timedClose);
};


/**
* Show a success messages to the user
* @function showSuccess
* @memberof NotificationBox
* @instance
* @param {String} message - a message (html allowed) to display to the user
* @param {Integer} [timedClose] - the time (in ms) after which to close this one message
*/
NotificationBox.prototype.showSuccess = function showSuccess(message, timedClose) {
  this.collapse();
  this.$messageContainer.html(message);
  this.currentState = 'success';
  this.expand();
  this.initTimedClose(timedClose);
};


/**
* Show a generic notice messages to the user
* @function showNotice
* @memberof NotificationBox
* @instance
* @param {String} message - a message (html allowed) to display to the user
* @param {Integer} [timedClose] - the time (in ms) after which to close this one message
*/
NotificationBox.prototype.showNotice = function showNotice(message, timedClose) {
  this.collapse();
  this.$messageContainer.html(message);
  this.currentState = 'notice';
  this.expand();
  this.initTimedClose(timedClose);
};


module.exports = NotificationBox;

